.next-steps .fa-link {
  margin-right: 5px;
}

/* Fix for use only flexbox in content area */
.next-steps .row {
  margin-bottom: 0;
}

.next-steps .col-md-5 {
  margin-bottom: 3rem;
}

.rule {
  background: linear-gradient(to right, transparent 0%, #cccccc 10%, #ffffff 50%, #cccccc 90%, transparent 100%);
  height: 1px; /* Adjust the height as needed */
  width: 80%;
  margin: 20px 0; /* Adjust the margin as needed */
}

#qsLoginBtn {
    background: linear-gradient(to right, #007bff, #0dcaf0);
    background-color: #0dcaf0!important;
    border: none;
    color: #ffffff;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
    transition: background 0.3s ease-in-out;
    font-weight: bold;
    font-family: "Poppins", sans-serif !important;
    cursor: pointer;
    font-size: 20px;
    margin-bottom: 0px;
}
#qsLStartBtn {
    background: linear-gradient(to right, #198754, #198754);
    background-color: #198754!important;
    border: none;
    color: #ffffff;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
    transition: background 0.3s ease-in-out;
    font-weight: bold;
    font-family: "Poppins", sans-serif !important;
    cursor: pointer;
    font-size: 20px;
    margin-bottom: 0px;
}
#qsLStartBtn:hover {
  background: linear-gradient(to right, lightgreen, #198754); /* Adjust colors as needed */
}
th {
  position: relative;
}
.catgo {
  float: right;
  position: absolute;
  top: 4px;
  right: 6px;
  font-weight: 400;
  text-transform: uppercase;
  color: #bebebe;
  font-size: 17px;
}
.btncontainer {
  display: flex;
  /* padding-bottom: 40px; */
}
#qsLoginBtn:hover {
  background: linear-gradient(to right, #0056b3, #007bff); /* Adjust colors as needed */
  
}
.btncontainer .btn-block {
  padding: 0px;
  margin: 0px;
  height: 48px;
  border-radius: 10px;
  margin: 0px 8px 20px 8px;
}
#qsCancelBtn {
  background: linear-gradient(to right, #6c757d, #495057); /* Adjust colors as needed */
  border: none;
  font-weight: bold;
    font-family: "Poppins", sans-serif !important;
    cursor: pointer;
    font-size: 20px;
  color: #ffffff; /* Text color */
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.2); /* Optional: Add a subtle text shadow */
  transition: background 0.3s ease-in-out; /* Optional: Add a smooth transition effect */
}
/* a {
  color: #0dcaf0 !important;
} */
.notfound {
  font-family: "Poppins", sans-serif !important;
  text-align: center;
}
#qsCancelBtn:hover {
  background: linear-gradient(to right, #495057, #6c757d); /* Adjust colors as needed */
}
.logo-tcg {
  padding-top: 4px;
  padding-bottom: 14px;
}
.logo-pig {
  width: 560px
}
.labelil {font-size: 11px;}
.logo-tcg2 {
  padding-top: 4px;
    text-align: center;
    width: 140px;
    float: right;
    padding-bottom: 0px;
    font-style: italic;
    margin-bottom: 0px !important;
    /* margin-rgih-valut: 18px; */
}
.caprio {

}


.spinner {
  position: absolute;
  display: flex;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background-color: white;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.result-block-container .result-block {
  opacity: 1;
}
h1 {
  font-size: 22px;
}
.btn-game {
  display: flex;
  font-size: 18px;
  font-weight: bold;
  border-radius: 10px;
}
.row-container {
  width: 100%;
  flex-direction: row;
  margin-top: 0px;
  display: flex;
}
.smllrow {
  flex: .4;
}
.bigrow {
  flex: 1;
}
.navbar>.container {
  display: flex;
  flex-direction: row;
  height: 100%;
}
.app-logo {
  border: 0;
  outline: 0;
  justify-content: center;
  height: 64px;
  padding: 0px;
  margin: 0px;
  display: flex;
  align-items: center;
  margin-right: 15px;
  margin-top: 6px;
  margin-left: 10px;
}
.active-game {
  display: flex;
}
.game-table {
  width: 100%;
}
.image-area {
  display: flex;
  padding-top: 12px;
  font-size: 14px;
}
.btn-primary.disabled, .btn-primary:disabled {
  cursor: not-allowed;
  /* display: none; */
}
.label-spacing {
  padding-right: 15px;
}
.red-btn {
  background-color: red;
  border-color: darkred;
}
.green-btn {
  background-color: green;
  border-color: darkgreen;
  justify-content: center;
}
.colly {
  flex-direction: column;
  display: flex;
  flex: .4;
  text-align: center;
}
.main-img {
  width: 250px;
  height: 250px;
  margin: 0 auto;
}
.image-holder {
  display: flex;
}
.collypad {
  flex-direction: column;
  display: flex;
  text-align: center;
  flex: 1;
}
.thumb-holder {
  display: flex;
  margin-bottom: 32px;
  margin-top: 8px;
}
.thumb-img {
  height: 60px;
  width: 60px;
  margin: 0 auto;
}
.thumb-div {
  flex: 1;
  display: flex;
  flex-direction: column;
  font-size: 11px;
}
.descrip {
  text-align: center;
  font-size: 23px;
  padding-top: 12px;
}
.align-cent {
  text-align: center;
}
.game-id {
  justify-content: space-between;
  color: blue;
  padding: 20px 10px;
}
.caption {
  padding-top: 8px;
}
.status {
  margin-top: 20px;
  font-size: 18px;
  opacity: .5;
}
.titles {
  text-align: center;
  font-size: 14px;
  padding-top: 4px;
  font-weight: bold;
}

.knobber {
  width: 240px;
  left: -15%;
  position: absolute;
  top: -13%;
}

@keyframes spin {
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
.mt-5, .my-5 {
  margin-top: 2rem!important;
}
.whitec {
  color: white !important
}
.logoholder {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.dlogo {
  margin-top: 25%
}
.ui-inputgroup select {
  word-wrap: normal;
  border-radius: 6px;
  height: 42px;
  padding-left: 7px;
  padding-right: 7px;
}
.ui-inputgroup {
  display: flex;
  flex-direction: column;
  margin-bottom: 22px;
}
.ui-inputgroup input {
  border-radius: 6px;
}
.ui-inputgroup label {
  font-size: 24px;
}

.form1 {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.form1 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.form1 > div {
  width: 100%; /* Items will take 100% width by default */
  box-sizing: border-box; /* Ensure padding and borders are included in the width */
  font-family: "Poppins", sans-serif;
}

.form1 > div.half-size {
  width: 49%; /* Items with this class will take 50% width */
}
.rdp-header {
  color: black;
}
.form1 > div.half-size:nth-child(3), .form1 > div.half-size:nth-child(5), .form1 > div.half-size:nth-child(7), .form1 > div.half-size:nth-child(9) {
  margin-right: 2%;
}


body {
	/* background : radial-gradient(circle at center, grey 0, #181818 30%, black 100%) */
}
.form-head {
  display: flex;
  align-items: center;
  justify-content: center;
}
/* .form-head h3 {
  font-family: "Poppins", sans-serif !important;
  font-family: "Protest Riot", sans-serif;
  color: gray;
  font-size: 26px;
} */
.ui-inputgroup label {
  font-size: 18px;
  padding-bottom: 0px;
  margin-bottom: 3px;
  font-weight: 500;
}
.main-content {
  max-width: 620px;
}
.dcontainer {
	width : 300px;
	height: 400px;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}


.dcover {
  display: inline-block;
  height: 400px;
	width: 300px;
	margin: 15px;
	border-radius: 8px;
}

.atvImg {
	border-radius: 8px;
	transform-style: preserve-3d;
	-webkit-tap-highlight-color: rgba(#000,0);
}

.atvImg img {
	border-radius: 8px;
	box-shadow: 0 2px 8px rgb(0, 0, 0);
}

.atvImg-container {
	position: relative;
	width: 100%;
	height: 100%;
	border-radius: 8px;
	transition: all 0.2s ease-out;
}

.atvImg-container.over .atvImg-shadow {
	box-shadow: 0 45px 100px rgba(0, 0, 0, 1), 0 16px 40px rgba(0, 0, 0, 1);
}

.atvImg-layers {
	position: relative;
	width: 100%;
	height: 100%;
	border-radius: 8px;
	overflow: hidden;
	transform-style: preserve-3d;
}

.atvImg-rendered-layer {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0%; 
	left: 0%;
	background-repeat: no-repeat;
	background-position: center;
	background-color: transparent;
	background-size: cover;
	transition: all 0.1s ease-out;
	overflow: hidden;
	border-radius: 8px;
}

.atvImg-shadow {
	position: absolute;
	top: 5%; 
	left: 5%;
	width: 90%;
	height: 90%;
	transition: all 0.2s ease-out;
	box-shadow: 0 8px 30px rgba(14,21,47,0.6);
}

.atvImg-shine {
	position: absolute;
	top: 0; 
	left: 0; 
	right: 0; 
	bottom: 0;
	border-radius: 8px;
	background: linear-gradient(135deg, rgba(255,255,255,.25) 0%,rgba(255,255,255,0) 60%);
}
.ui-inputgroup input {
  border-radius: 6px;
  height: 42px;
  padding-left: 7px;
}


@media (max-width: 768px) {
  .btncontainer {
    display: flex;
    flex-direction: column;
  }
  .next-steps .col-md-5 {
    margin-bottom: 0;
  }
  .form1 > div.half-size {
    width: 100%;
    margin-right: 0px !important;
}
.form1 {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 97%;
    margin: 0 auto;
}

}

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #000;
  font-family: "Poppins", sans-serif !important;
}
.linksp {
  font-size: 18px;
  padding-top: 8px;
  font-family: "Poppins", sans-serif !important;
  font-weight: 600;
  text-decoration: underline;
  color: #0dcaf0!important;
  cursor: pointer;
}
.headyc {
  display: flex !important;
  flex-direction: column;
  text-align: center !important;
}
.ReactModal__Overlay {
  background: none;
  background-color: #000 !important;
  border-color: #000 !important;
}
.ReactModal__Content table {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  margin-top: 40px;
}
.ReactModal__Content {
  background-color: #000 !important;
  color: white !important;
  border: 0px !important;
  inset:0px !important;
  padding: none !important;
}
.custom-table {
  width: 100%;
  color: white;
  border-collapse: collapse;
}

.custom-table th, .custom-table td {
  border: 1px solid #444;
  padding: 8px;
}

.even-row {
  background-color: black;
}

.odd-row {
  background-color: #1a1a1a; /* Light black color */
}

/* Additional styles for approve and delete buttons */
.custom-table div {
  padding: 5px;
  cursor: pointer;
  margin: 2px;
  background-color: #3498db; /* Example color, you can change this */
  color: white;
  text-align: center;
}

.custom-table div:hover {
  background-color: #2980b9; /* Example color, you can change this */
}

.buttono {
  font-weight: bold;
  border-radius: 5px;
  background-color: #198754!important;
  width: 49%;
  height: 51px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.buttono.del {
  font-weight: bold;
  border-radius: 5px;
  background-color: #dc3545!important
}
.modal-headers {
  
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #333; /* Adjust the background color as needed */
}

.modal-headers h2 {
  color: white;
  font-family: "Poppins", sans-serif !important;
  margin: 0;
}

.close-button {
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  font-size: 16px;
}
.button-container {
  display: flex;
  place-content: space-between;
}
.ui-inputgroup input {
  width: 100%;
}
.grade-holder {
  display: flex;
  /* justify-content: center; */
 font-size: 18px;
  align-items: center;
}
.grade-holder h2 {
 font-size: 26px;
 margin-left:  8px;
}
.pigcontainer {
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 100vh; */
  flex-direction: column; /* This makes the container take the full height of the viewport */
  width: 100%;
  transition: background-color .5s ease; /* Add transition for background color */
  max-width: 100% !important;
}

.pigcontainer img {
  max-width: 100%;
  max-height: 100%;
}

.pigcontent {
  /* Additional styles for your content if needed */
}


.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* height: 100vh; */
  /* background-color: #fff;  */
  max-width: 100% !important;
  margin: 0px !important;
  padding: 0px !important;
}

.transition {
  background-color: #5f00a0; /* Change background color during transition */
}

.logo-pig {
  max-width: 100%;
  max-height: 100%;
}

.pigcontent {
  text-align: center;
}

#pigbutton {
  color: #b52a85;
  cursor: pointer;
  text-transform: uppercase;
  background-color: transparent;
  border: 6px solid #b52a85;
  border-radius: 35px;
  padding: 7px 36px;
  font-size: 31px;
  font-family: "Anton", sans-serif;
  font-weight: 500;
  margin-top: 20px;
  
}
#pigbutton:hover {
  background-color: white;
}
.content {
  /* margin-top: 20px; */
  font-size: 18px;
  color: #fff;
}
.lilogo {
  position: absolute;
  top: 21px;
  left: 41px;
  width: 159px;
}
.lilpigbtn {
  border-color: white !important;
    color: white !important;
    font-size: 18px !important;
    border: 3px solid white !important;
    position: absolute;
    bottom: 50px;
    right: 50px;
}
#pigbutton.lilpigbtn:hover {
  background-color: #ff6666;
}
.loadingpig {
  width: 150px;
}
.loadingtext {
  color: #b52a85;
  font-size: 24px;
}
.generated {
  font-size: 72px;
  max-width: 86%;
  margin: 0 auto;
}
.glabel {
  padding-right: 18px;
  color: #cc66ff;
}
.glabel:nth-child(2) {color: #ff6666;}
.glabel:nth-child(4) {color: #00ecec;}
@keyframes wobble {
  0% { transform: translateX(0); }
  15% { transform: translateX(-10px) rotate(-5deg); }
  30% { transform: translateX(10px) rotate(5deg); }
  45% { transform: translateX(-10px) rotate(-3deg); }
  60% { transform: translateX(10px) rotate(3deg); }
  75% { transform: translateX(-5px) rotate(-2deg); }
  100% { transform: translateX(0); }
}

.wobble {
  animation: wobble 1s ease infinite;
}

.wobble-slow {
  animation: wobble 1s ease; /* Run the animation once */
  animation-iteration-count: 1; /* Run the animation twice */
}
.how-value {
  color: #ffca3a !important; /* Change the color as desired */
}
.navbar-expand-md {
  /* background-color: #f7ce46 !important; */
  flex: 1;
  padding: 0 0 0 0;
  display: flex;
}
.navbar-expand-md .nav-link {
  font-family: "Raleway", sans-serif;
    color: #000;
    font-size: 20px;
    font-weight: 500;
}
.navbar .router-link-exact-active {
  font-weight: 900 !important;
  border-bottom: 0px;
}
.mr-auto {
  margin-left: 18px;
  margin: 0 auto;
}
.orderbutton {
  height: 100%;
  /* background-color: #bb271a; */
  font-family: "Raleway", sans-serif;
  color: white;
  font-weight: 900;
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 14px;
}
.rewardstab {
  cursor: pointer;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #b89230;
  padding: 0 15px;
  color: white;
  font-family: "Raleway", sans-serif;
  font-size: 16px;
  font-weight: 800;
}
.nav-container {
  display: flex;
  /* background-color: #f7ce46 !important; */
  -webkit-box-shadow: 0px 11px 13px -7px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 11px 13px -7px rgba(0,0,0,0.75);
  box-shadow: 0px 11px 13px -7px rgba(0,0,0,0.75);
  z-index: 99;
}
.icocirc {
  background-color: black;
  border-radius: 30px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 4px;
}
.slheader {
  position: absolute;
  top: 50%;
  left: 50px; /* Adjust as needed */
  transform: translateY(-50%);
}

.slslideshow {
  /* Your slideshow styling */
  /* Ensure it takes up 100% width */
  width: 100%;
  /* Ensure it maintains the aspect ratio */
  height: 0;
  padding-top: 56.25%; /* Assuming 16:9 aspect ratio */
  position: relative;
}

/* Style for images inside the slideshow */
.slslideshow img {
  /* Ensure images take up the full width of the container */
  width: 100%;
  /* Ensure images maintain aspect ratio */
  height: 100%;
  /* Make images fill the container without stretching */
  object-fit: cover;
}
.carousel.slide {
  max-width: 100%;
  max-height: 1351px;
  aspect-ratio: 1756 / 619;
}
.carousel-caption {
  position: absolute;
  top: 35%;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: left;
  right: auto;
}
.carousel-caption h3 {
  font-family: "Lilita One", sans-serif;
  font-weight: 500;
  font-size: 7vw;
  text-shadow: 4px 2px 10px rgba(0, 0, 0, 0.5);
}
/* Slideshow.css */
.delbut {
  padding: 6px 0px;
  background-color: #c12814;
  color: white;
  font-size: 18px;
  font-family: "Lilita One", sans-serif;
  margin: 3px 4px;
  border-radius: 6px;
}
.editbut {
  padding: 6px 0px;
  background-color: #0d4a7c;
  color: white;
  font-size: 18px;
  font-family: "Lilita One", sans-serif;
  margin: 3px 4px;
  border-radius: 6px;
}
.menu-card-details {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.carousel-caption p {
  font-family: "Lilita One", sans-serif;
  background-color: var(--secondary-color) !important;
  font-size: 4vw;
  -webkit-box-shadow: 0px 25px 13px -7px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 25px 13px -7px rgba(0,0,0,0.75);
  box-shadow: 0px 25px 13px -7px rgba(0,0,0,0.75);
}
.dishes {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.price-men {
  font-size: 20px;
  font-family: 'raleway';
  font-weight: 500;
}
.formhold {
  display: flex;
  flex-direction: column;
}
.dish {
  width: calc(22% - 40px);
  margin: 10px;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  box-sizing: border-box;
  height: 344px;
  display: flex;
  flex-direction: column;
  background-size: contain;
  background-position: top;
  border-radius: 14px;
  /* background-image: url("assets/menu1.jpg"); */
}
.location {
  width: calc(42% - 40px);
  margin: 10px;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  box-sizing: border-box;
  height: 500px;
  display: flex;
  flex-direction: column;
  background-size: contain;
  background-position: top;
  border-radius: 14px;
  /* background-image: url("assets/menu1.jpg"); */
}
.dishdetails {
  background-color: white;
  padding: 10px 10px;
  margin-top: auto;
  display: flex;
  flex-direction: column;
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px;
  border: 0px;
  height: 45%;
}
.dishtitle {
  font-family: "Raleway", sans-serif;
  font-size: 25px;
  font-weight: 700;
  text-align: center;
  color: #000000;
  padding-bottom: 0px;
  margin-bottom: 2px;
}
.dishdescription {
  font-family: "Raleway", sans-serif;
    font-size: 14px;
    font-weight: 400;
    font-style: italic;
    text-align: center;
}
.abouttext {
  font-family: "Raleway", sans-serif;
  font-size: 18px;
  line-height: 26px;
  width: 70%;
  margin: 0 auto
}
.address {
  font-family: "Raleway", sans-serif;
  font-size: 16px;
  text-align: center;
}
/* .footersl a {
  color: #f7ce46 !important
} */
.footersl {
  min-height: 46px;
  background-color: #111 !important;
  color: #efefef;
  text-align: left !important;
  font-family: "Raleway", sans-serif;
  font-weight: 600;
}
.footerstu {
  float: right;
}

.navbar-expand-md .navbar-collapse {
  /* height: 100%; */
  height: 105%;
    margin-bottom: 0px;
    margin-top: -2px;
}
.linkjawn {
  height: 100%;
}
.expad-t {
  margin-top: 44px;
}
.expad {
  padding-top: 38px;
  padding-bottom: 18px;
}
.fullwidth {
  width: 100%;
}

.admin-cont {
  display: flex;
  flex-direction: column;
  width: 90%;
}

.admin-cont .btn-primary {
  
}
/* .admin-cont .btn-primary:focus,  .admin-cont .btn-primary:active {
  border: 1px solid #bb271a;
  background-color: #bb271a;
} */
.update-bt {
  /* width: 100%; */
  float: right;
  padding: 8px 40px;
  font-size: 18px;
  font-weight: 500;
  margin-top: 10px;
  border-radius: 10px;
}
.AccordionButton {
  background-color: #d9d9d9;
  border: 1px solid white;
  color: black;
  font-size: 26px;
  border-radius: 10px;
  font-weight: 900;
  font-family: "Raleway", sans-serif;
  outline: none !important; /* Remove the outline */
  /* background-color: #d9d9d9; */
  border: 0px solid white;
  margin-top: 1rem;
  width: 100%;
}

/* Custom styles for the hover state */
.AccordionButton:hover {
  border: 0px solid rgb(136, 0, 0);
  background-color: rgb(136, 0, 0) !important;
  color: white !important
}
.newform {
  display: flex;
    flex-direction: column;
    width: 75%;
    margin-bottom: 30px;
}
.menucomp {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.card-body {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}
.menu-card {
  background-color: #f0f0f0;
    background-position: top;
    background-size: contain;
    border: 1px solid #ccc;
    border-radius: 14px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    margin: 10px;
    width: 47%;
}
.active-but {
  border: 0px solid rgb(136, 0, 0);
  background-color: rgb(136, 0, 0) !important;
  color: white !important
}
/* Custom styles for the active state */
.AccordionButton:active {
  border: 0px solid #bb271a;
  background-color: #bb271a;
}
.subsub {
  width: 100%;
}
.currentlog {
  float: right;
  height: 50px;
}
.catfield {
  display: flex;
  padding-top: 6px;
  padding-bottom: 6px;
}
.catfield span {
  margin-right: 10px;
}
.aboutusimage {
  float: right;
  margin: 10px 0px 10px 28px;
  border-radius: 25px;
  max-width: 45%;
}
.slidercomp .table, .menucomp .table {
  color: black !important
}
.captiona {
  font-size: 13px;
  font-weight: 500;
  font-family: 'Raleway';
  padding-bottom: 10px;
  font-style: italic;
}
.logoutb {
  float: right;
  padding-top: 30px;
  text-align: right;
  width: 88%;
  margin: 0 auto;
  padding-bottom: 0px;
  margin-bottom: -10px;
}
.menu {
  width: 100%;
}
.logoutb span {
  cursor: pointer;
}
.menu-card-actions {
  display: flex;
}
.menu-card-actions button {
 flex: 1;
}
.mendesc {
  font-family: 'Raleway';
  font-size: 20px;
  padding-top: 12px;
  font-weight: 600;
  padding-bottom: 0px;
  margin-bottom: 0px;
  text-align: center;
}
.menu-cat {
  font-weight: 400;
    font-family: 'Raleway';
    padding: 0px;
    margin: 0px;
}
.menu-image-holder {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
}
@media screen and (max-width: 760px) {
  .menu-card {
    width: 100% !important;
}
  .abouttext {
    width: 90%;  
  }
  .rewardstab, .orderbutton {
    height: auto !important;
    padding: 14px 0;
  }
  .carousel-caption {
    top: 14% !important;
  }
  .d-none {
    display: block !important;
  }
  .dish, .location {
    width: calc(50% - 20px);
    margin: 10px 6px;
  }
  .footerstu {
    display: none;
  }
  .generated {
    font-size: 38px !important;
    max-width: 100% !important;
}

	.dcontainer {
		width : 100%;
		position: relative;
		transform: translate(0%, 0%);
		top: 0;
		left: 0;
	}
	.dcover {
		display: block;
		height: 300px;
		width: 300px;
		margin: 15px auto;
	}
  .currentlog {
    padding-left: 10px;
    padding-bottom: 12px;
    margin-bottom: 3px;
    float: inherit;
  }
}

.navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0,0,0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E") !important;
}
